import BonfireAPI from "../../../../AppBase/Core/Data/Http";
import {GET_SESSION_WITH_PROVIDER} from "../../../Models/SessionStatus/urls";
import SessionStatusModel from "../../../Models/SessionStatus";

class JoinSessionViewModel {

    static async processJoinProvider(type: string, options: any): Promise<SessionStatusModel | Error | null> {

        if (type === "zoom" || type == "teams" || type == "boostevents") {
            return JoinSessionViewModel.processWithProvider(type, options.meetingId);
        }

        return null;

    }

    static async processWithProvider(type: string, meetingId: string) {

        return BonfireAPI.request(GET_SESSION_WITH_PROVIDER,
            SessionStatusModel, { type, options: { meetingId }});

    }

}

export default JoinSessionViewModel;
