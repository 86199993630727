import development from "./envs/development.json";
import productionEU from "./envs/prod-eu.json";
import productionUS from "./envs/prod-us.json";
import sandbox from "./envs/sandbox.json";
import staging from "./envs/staging.json";

enum Environment {
  development = "development",
  production = "productionEU",
  productionUS = "productionUS",
  sandbox = "sandbox",
  staging = "staging"
}

var env: Environment;
switch (process.env.REACT_APP_ENV) {
  case "development":
    env = Environment.development;
    break;
  case "production":
    env = Environment.production;
    break;
  case "prod-us":
    env = Environment.productionUS;
    break;
  case "sandbox":
    env = Environment.sandbox;
    break;
  case "staging":
    env = Environment.staging;
    break;
  default:
    env = Environment.productionUS;
}

const config = {
  development,
  productionEU,
  productionUS,
  sandbox,
  staging,
};

export default config[env];
