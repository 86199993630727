import { Box, Text } from "@chakra-ui/react";
import T from "../../../Core/Translations";
import useLanguagesDropdown from "../Hooks";
import { Select } from "chakra-react-select";

const LanguagesDropdown = (props: {
  mt?: number;
  title?: string;
  initialLanguages?: { value: string; label: string }[];
  initialLangCodes?: string[];
  selectedLanguages?: { value: string; label: string }[];
  onChange: (languages: { label: string; value: string }[]) => void;
  onLoaded: () => void;
}) => {
  const { languagesList, onChangeSelectedLanguages } =
    useLanguagesDropdown(props);

  return (
    <Box mt={props.mt ? props.mt : 0}>
      {props.title ? (
        <Text fontSize={14} fontWeight={400} pb={2}>
          {props.title
            ? props.title
            : T.get("Translate to languages (optional)")}
        </Text>
      ) : null}
      <Select
        isMulti
        closeMenuOnSelect={true}
        defaultValue={props.initialLanguages?.filter((language) => language.value !== "floor")}
        useBasicStyles
        chakraStyles={{
          control: (provided) => ({
            ...provided,
            _light: {
              backgroundColor: "white",
            },
            _dark: {
              backgroundColor: "black",
            },
            shadow: "0px 4px 4px 0px rgba(74, 74, 74, 0.04)",
          }),
        }}
        maxMenuHeight={150}
        options={languagesList}
        placeholder={T.get("Add languages to session")}
        onChange={onChangeSelectedLanguages}
        value={props.selectedLanguages}
      />
    </Box>
  );
};

export default LanguagesDropdown;
